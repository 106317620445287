<template>
  <!-- v-if="hasPermission('admin_system')" -->

  <div>
    <b-row>

      <b-col md="12" class="3">
        <b-card class="mb-1">
          <b-row>
            <b-col md="3" class="">
              <v-select v-model="auditor_id" v-if="auditors_lookups"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'" :options="auditors_lookups" label="name"
                :reduce="(val) => val.id" placeholder="Auditor" @input="refresh" />
            </b-col>

            <b-col md="3">
              <b-form-group>
                <b-form-datepicker reset-button v-model="start_date" placeholder="From " dropright @input="refresh" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group placeholder=" To">
                <b-form-datepicker reset-button v-model="end_date" placeholder="To " dropright @input="refresh" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
    </b-row>
    <b-overlay :show="pageLoad" rounded="sm">
      <b-row>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="closed_cases && filter_auditor">
                  {{ closed_cases.count }}
                </h1>
                <p>Closed Cases</p>
              </div>
              <div>
              <!-- <b-link
              v-if="hasPermission('show_cases')"
              style="font-weight: bold; font-size: 12px; color: #054978"
              :to="{ name: 'case-show', params: { id: 497144 } }"
              >
              {{ closed_cases.count }}
              </b-link> -->
                <b-avatar class="mb-1" variant="success" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>
            </div>
          </b-card>
        </b-col>
  


        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="to_be_reviewed_cases && filter_auditor">
                  {{ to_be_reviewed_cases.count }}
                </h1>
                <p>To Be Reviewed Cases </p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="primary" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="pending_branch_cases && filter_auditor">
                  {{ pending_branch_cases.count }}
                </h1>
                <p>Pending Branch Feedback</p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="danger" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>
            </div>
          </b-card>
        </b-col>


      </b-row>

      <b-row>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="assigned_cases && filter_auditor">{{ assigned_cases.count }}</h1>
                <p>Total Assigned Cases</p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="info" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>
            </div>
          </b-card>
        </b-col>


      </b-row>

      <b-card>
        <div class="d-flex">

        </div>


        <b-row>
          <!-- <feather-icon icon="ClipboardIcon" size="20" /> -->
          <b-col md="12">
            <h4> Monthly Cases </h4>
          </b-col>
        </b-row>
        <!-- && auditor_id !== null -->
        <chartjs-component-bar-chart :options="options" :height="380" :data="AuditorsData"
          v-if="auditors && filter_auditor" />
      </b-card>
      <!-- <b-card>

        <b-row>
          <b-col md="12">
        
            <h4>   Cumulative All Cases</h4>
          </b-col>
           
                 
              
        </b-row>
     
        <chartjs-component-bar-chart
        :options="options"
        :height="380"
        :data="AuditorsDataCumulative"
        v-if="auditors && filter_auditor "
      />
    </b-card> -->
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BTable,
  BCol,
  BRow,
  BImg,
  BButton,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormDatepicker,
  BBadge,
  BLink,
  BOverlay,
} from "bootstrap-vue";

import pieChartCategory from "@/views/dashboard/component/pieChart.vue";
import ChartjsComponentBarChart from "@/views/components/charts-components/ChartjsComponentBarChart.vue";
import ChartjsComponentHorizontalBarChart from "@/views/components/charts-components/ChartjsComponentHorizontalBarChart.vue";
import ChartjsComponentLineChart from "@/views/components/charts-components/ChartjsComponentLineChart.vue";
// import Statistics from "@/views/components/info/statistics";
import vSelect from "vue-select";

import { mapGetters } from "vuex";

import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
  mainColor: "#054978",
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#746D69",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
  second_color: "#f57f21",
};

export default {
  props: {
    small: Boolean,
    icon: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: 'primary',
    },
  },
  components: {
    BTable,
    BFormDatepicker,
    vSelect,
    BCard,
    BCol,
    BBadge,
    BLink,
    BImg,
    BRow,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,

    ChartjsComponentBarChart,
    pieChartCategory,
    ChartjsComponentLineChart,
    ChartjsComponentHorizontalBarChart,
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      status_options: [
        {
          id: 3,
          name: "Closed Cases",
        },
        {
          id: 2,
          name: "Updated Cases",
        },
        {
          id: 0,
          name: "New Cases",
        },
        {
          id: 1,
          name: "Pending Branch Feedback Cases",
        }

      ],
      pageLoad: false,
      compliance: null,
      status: null,
      filter_doctor: true,
      filter_auditor: true,
      filter: { removed: 0 },
      itemss: [],
      dr_name: null,
      auditor_id: null,
      branch_name: null,
      data: null,
      options2: {
        elements: {
          point: {
            radius: 25,
          },
          rectangle: {
            radius: 25,
            borderRadius: 0,

            borderWidth: 0,
            borderSkipped: "bottom",
          },
        },
        barRoundness: 0,
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          borderRadius: 0,
        },
        scales: {
          xAxes: [
            {
              stacked: true,

              scaleLabel: {
                display: true,
                labelString: "Reviewer",
              },

              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },

              ticks: {
                fontColor: chartColors.labelColor,

                beginAtZero: true,
                stepSize: 50,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,

              scaleLabel: {
                display: false,
                //   labelString: 'Number Of Cases'
              },
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 200,
                beginAtZero: true,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [

            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Admission Date'
              },



              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },

              ticks: {
                fontColor: chartColors.labelColor,
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
          yAxes: [

            {
              stacked: true,
              scaleLabel: {
                display: false,
                //   labelString: 'Number Of Cases'
              },
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 30,
                beginAtZero: true,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    };
  },
  computed: {

    ...mapGetters({
      counts: "dashboard/counts",
      faculty: "dashboard/faculty",
      //   activities: 'dashboard/activities',
      auditors: "dashboard/all_auditors",
      auditors_lookups: "dashboard/auditors_lookups",
      assigned_cases: "dashboard/assigned_cases",
      pending_branch_cases: "dashboard/pending_branch_cases",
      closed_cases: "dashboard/closed_cases",
      to_be_reviewed_cases: "dashboard/to_be_reviewed_cases",
      payment: "dashboard/payment",
      items: "cases/items",
      load: "cases/load",
      total: "cases/total",
      lookups: "cases/lookups",
    }),
    AuditorsData() {
      console.log("zzz" , this.auditors);
      if (this.auditors) {
        return {
          labels: Array.from(this.auditors, (el) => el.label),
          datasets: [
            {
              label: "Closed",
              data: Array.from(this.auditors, (el) => el.closed),
              backgroundColor: "#28c76f",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },
            {
              label: "To Be Reviewd",
              data: Array.from(this.auditors, (el) => el.new),

              backgroundColor: "#771034",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },

            {
              label: "Pending  Branch Feedback",
              data: Array.from(this.auditors, (el) => el.pending),

              backgroundColor: "#ec5556",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },


            //    rgb(255, 99, 132)
          ],
        };
      }
    },

    AuditorsDataCumulative() {
      console.log(this.status);
      let filter_label = "All Cases";

      this.status_options.forEach((element) => {
        if (element.id == this.status) {
          filter_label = element.name;
        }
      });

      if (this.auditors) {
        const new_monthly_count = [];
        const updated_monthly_count = [];
        const closed_monthly_count = [];
        const pending_monthly_count = [];
        this.auditors.forEach((element) => {
          new_monthly_count.push(element.new);
          // updated_monthly_count.push(element.updated);
          closed_monthly_count.push(element.closed);
          pending_monthly_count.push(element.pending);
        });

        const new_cumulative = [];
        // const updated_cumulative = [];
        const pending_cumulative = [];
        const closed_cumulative = [];

        new_monthly_count.forEach((number, index) => {
          new_cumulative.push(number + (new_cumulative[new_cumulative.length - 1] || 0));
        });
        // updated_monthly_count.forEach((number, index) => {
        //   updated_cumulative.push(number + (updated_cumulative[updated_cumulative.length - 1] || 0));
        // });

        pending_monthly_count.forEach((number, index) => {
          pending_cumulative.push(number + (pending_cumulative[pending_cumulative.length - 1] || 0));
        });
        closed_monthly_count.forEach((number, index) => {
          closed_cumulative.push(number + (closed_cumulative[closed_cumulative.length - 1] || 0));
        });
        return {
          labels: Array.from(this.auditors, (el) => el.label),
          datasets: [
            {
              label: "Closed",
              data: closed_cumulative,
              backgroundColor: "#28c76f",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },
            {
              label: "To Be Reviewed",
              data: new_cumulative,

              backgroundColor: "#771034",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },
            // {
            //   label: "Updated",
            //   data: updated_cumulative,

            //   borderRadius: 20,
            //   backgroundColor: "#28c76f",
            //   borderColor: "transparent",
            //   borderWidth: 0,
            //   borderRadius: 20,
            //   border: 0,

            //   borderSkipped: false,
            // },
            {
              label: "Pending  Branch Feedback",
              data: pending_cumulative,

              backgroundColor: "#ec5556",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },


            //    rgb(255, 99, 132)
          ],
        };
      }
    },

    programs() {
      if (this.faculty && this.faculty.programs_per_faculty) {
        return {
          labels: Array.from(
            this.faculty.programs_per_faculty,
            (el) => el.name
          ),
          datasets: [
            {
              data: Array.from(
                this.faculty.programs_per_faculty,
                (el) => el.programs_count
              ),
              backgroundColor: chartColors.second_color,
              borderColor: "transparent",
            },
          ],
        };
      }
    },

    courses() {
      if (this.faculty && this.faculty.courses_per_faculty) {
        return {
          labels: Array.from(this.faculty.courses_per_faculty, (el) => el.name),
          datasets: [
            {
              data: Array.from(
                this.faculty.courses_per_faculty,
                (el) => el.courses_count
              ),
              backgroundColor: chartColors.second_color,
              borderColor: "transparent",
            },
          ],
        };
      }
    },
  },
  methods: {
    getStatistic() {
      this.pageLoad = true;
      //   this.$store.dispatch('dashboard/Activities');
      this.$store.dispatch("dashboard/auditors").then(_=>{
        this.pageLoad = false;
      })
      this.$store.dispatch("dashboard/auditors_lookups");
      // this.$store.dispatch('dashboard/paymentPerMonth');
    },
    refresh(query) {
      this.filter_auditor = false;
      console.log(query);
      this.$store
        .dispatch("dashboard/auditors", {
          auditor_id: this.auditor_id,
          compliance: this.compliance,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date
        })
        .then((_) => {
          this.filter_auditor = true;

        });

      // this.$store.dispatch('cases/cases', { query });
    },
  },
  mounted() {
    this.getStatistic();
    // this.refresh();
  },
};
</script>
